import { default as indexqlpWUtNWA6Meta } from "/codebuild/output/src1912607348/src/pages/[slug]/index.vue?macro=true";
import { default as accept_45inviteIjgmFDeE2aMeta } from "/codebuild/output/src1912607348/src/pages/accept-invite.vue?macro=true";
import { default as extra_45purchasebidszandaBMeta } from "/codebuild/output/src1912607348/src/pages/account/extra-purchase.vue?macro=true";
import { default as indextKLJi4kErQMeta } from "/codebuild/output/src1912607348/src/pages/account/index.vue?macro=true";
import { default as settingsoleM6R2b8zMeta } from "/codebuild/output/src1912607348/src/pages/account/settings.vue?macro=true";
import { default as subscriptionSLPDVG5I70Meta } from "/codebuild/output/src1912607348/src/pages/account/subscription.vue?macro=true";
import { default as transactionsexnS8vRJHmMeta } from "/codebuild/output/src1912607348/src/pages/account/transactions.vue?macro=true";
import { default as update_45subscriptionLpKn48FflqMeta } from "/codebuild/output/src1912607348/src/pages/account/update-subscription.vue?macro=true";
import { default as previewUASGfX88KYMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/emails/[id]/preview.vue?macro=true";
import { default as composexZrNrUrYxzMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/emails/compose.vue?macro=true";
import { default as indexguyqDqo1tuMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/emails/index.vue?macro=true";
import { default as emailssPd55KKuW9Meta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/emails.vue?macro=true";
import { default as editzIX0xNf7mPMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/events/[id]/edit.vue?macro=true";
import { default as registrationstKZtaMT14FMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/events/[id]/registrations.vue?macro=true";
import { default as indexjp1sai3XXpMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/events/index.vue?macro=true";
import { default as eventsQQTtjHnmi4Meta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/events.vue?macro=true";
import { default as filesm5hf6pyGP6Meta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/files.vue?macro=true";
import { default as gallery6mwCvbWRt2Meta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/gallery.vue?macro=true";
import { default as group_45detailsImG6K51COdMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/group-details.vue?macro=true";
import { default as group_45settingszWqeh0GqMRMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/group-settings.vue?macro=true";
import { default as indexFQr3sKVtdNMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/index.vue?macro=true";
import { default as membersF6U2YmL90mMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/members.vue?macro=true";
import { default as editR0WlpKTbypMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/news/[id]/edit.vue?macro=true";
import { default as indexVAM0bJ8draMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/news/index.vue?macro=true";
import { default as newsjoPCmwVuvFMeta } from "/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/news.vue?macro=true";
import { default as _91slug_93b315dTXnKOMeta } from "/codebuild/output/src1912607348/src/pages/donate/[slug].vue?macro=true";
import { default as indexV9hI5gYsTIMeta } from "/codebuild/output/src1912607348/src/pages/donate/index.vue?macro=true";
import { default as email_45unsubscribeEmbSNNyeeOMeta } from "/codebuild/output/src1912607348/src/pages/email-unsubscribe.vue?macro=true";
import { default as _91slug_9320ODnhQvPyMeta } from "/codebuild/output/src1912607348/src/pages/events/[slug].vue?macro=true";
import { default as index261DaQCIDeMeta } from "/codebuild/output/src1912607348/src/pages/events/index.vue?macro=true";
import { default as forgot_45passwordRUq60wvAcKMeta } from "/codebuild/output/src1912607348/src/pages/forgot-password.vue?macro=true";
import { default as filesyKGrwIDYtUMeta } from "/codebuild/output/src1912607348/src/pages/groups/[slug]/files.vue?macro=true";
import { default as gallerynOX0T1u0ZmMeta } from "/codebuild/output/src1912607348/src/pages/groups/[slug]/gallery.vue?macro=true";
import { default as indexi6kRk0cm0cMeta } from "/codebuild/output/src1912607348/src/pages/groups/[slug]/index.vue?macro=true";
import { default as shopq2QJwhckPqMeta } from "/codebuild/output/src1912607348/src/pages/groups/[slug]/shop.vue?macro=true";
import { default as _91slug_93DqusU4MnMzMeta } from "/codebuild/output/src1912607348/src/pages/groups/[slug].vue?macro=true";
import { default as indexjcz6BKENRKMeta } from "/codebuild/output/src1912607348/src/pages/groups/index.vue?macro=true";
import { default as index2GlfbpKuoWMeta } from "/codebuild/output/src1912607348/src/pages/index.vue?macro=true";
import { default as joinAfWm4w9P8nMeta } from "/codebuild/output/src1912607348/src/pages/join.vue?macro=true";
import { default as loginvOW5IAmOx5Meta } from "/codebuild/output/src1912607348/src/pages/login.vue?macro=true";
import { default as confirmationzVP4trFs5LMeta } from "/codebuild/output/src1912607348/src/pages/membership/confirmation.vue?macro=true";
import { default as indexYHxRnrVHCdMeta } from "/codebuild/output/src1912607348/src/pages/membership/index.vue?macro=true";
import { default as _91slug_93WekJTR0Pu2Meta } from "/codebuild/output/src1912607348/src/pages/news/[slug].vue?macro=true";
import { default as index4C8MaPtdopMeta } from "/codebuild/output/src1912607348/src/pages/news/index.vue?macro=true";
import { default as reset_45password89VKtyc6EuMeta } from "/codebuild/output/src1912607348/src/pages/reset-password.vue?macro=true";
import { default as setup_452fa1OqCBp8DTPMeta } from "/codebuild/output/src1912607348/src/pages/setup-2fa.vue?macro=true";
import { default as shopKmzJxA94xBMeta } from "/codebuild/output/src1912607348/src/pages/shop.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/codebuild/output/src1912607348/src/pages/[slug]/index.vue")
  },
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/codebuild/output/src1912607348/src/pages/accept-invite.vue")
  },
  {
    name: "account-extra-purchase",
    path: "/account/extra-purchase",
    meta: extra_45purchasebidszandaBMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/account/extra-purchase.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indextKLJi4kErQMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/account/index.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsoleM6R2b8zMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/account/settings.vue")
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionSLPDVG5I70Meta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/account/subscription.vue")
  },
  {
    name: "account-transactions",
    path: "/account/transactions",
    meta: transactionsexnS8vRJHmMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/account/transactions.vue")
  },
  {
    name: "account-update-subscription",
    path: "/account/update-subscription",
    meta: update_45subscriptionLpKn48FflqMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/account/update-subscription.vue")
  },
  {
    name: emailssPd55KKuW9Meta?.name,
    path: "/admin/groups/:slug()/emails",
    meta: emailssPd55KKuW9Meta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/emails.vue"),
    children: [
  {
    name: "admin-groups-slug-emails-id-preview",
    path: ":id()/preview",
    meta: previewUASGfX88KYMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/emails/[id]/preview.vue")
  },
  {
    name: "admin-groups-slug-emails-compose",
    path: "compose",
    meta: composexZrNrUrYxzMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/emails/compose.vue")
  },
  {
    name: "admin-groups-slug-emails",
    path: "",
    meta: indexguyqDqo1tuMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/emails/index.vue")
  }
]
  },
  {
    name: eventsQQTtjHnmi4Meta?.name,
    path: "/admin/groups/:slug()/events",
    meta: eventsQQTtjHnmi4Meta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/events.vue"),
    children: [
  {
    name: "admin-groups-slug-events-id-edit",
    path: ":id()/edit",
    meta: editzIX0xNf7mPMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/events/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-events-id-registrations",
    path: ":id()/registrations",
    meta: registrationstKZtaMT14FMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/events/[id]/registrations.vue")
  },
  {
    name: "admin-groups-slug-events",
    path: "",
    meta: indexjp1sai3XXpMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/events/index.vue")
  }
]
  },
  {
    name: "admin-groups-slug-files",
    path: "/admin/groups/:slug()/files",
    meta: filesm5hf6pyGP6Meta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/files.vue")
  },
  {
    name: "admin-groups-slug-gallery",
    path: "/admin/groups/:slug()/gallery",
    meta: gallery6mwCvbWRt2Meta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/gallery.vue")
  },
  {
    name: "admin-groups-slug-group-details",
    path: "/admin/groups/:slug()/group-details",
    meta: group_45detailsImG6K51COdMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/group-details.vue")
  },
  {
    name: "admin-groups-slug-group-settings",
    path: "/admin/groups/:slug()/group-settings",
    meta: group_45settingszWqeh0GqMRMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/group-settings.vue")
  },
  {
    name: "admin-groups-slug",
    path: "/admin/groups/:slug()",
    meta: indexFQr3sKVtdNMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/index.vue")
  },
  {
    name: "admin-groups-slug-members",
    path: "/admin/groups/:slug()/members",
    meta: membersF6U2YmL90mMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/members.vue")
  },
  {
    name: newsjoPCmwVuvFMeta?.name,
    path: "/admin/groups/:slug()/news",
    meta: newsjoPCmwVuvFMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/news.vue"),
    children: [
  {
    name: "admin-groups-slug-news-id-edit",
    path: ":id()/edit",
    meta: editR0WlpKTbypMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/news/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-news",
    path: "",
    meta: indexVAM0bJ8draMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/admin/groups/[slug]/news/index.vue")
  }
]
  },
  {
    name: "donate-slug",
    path: "/donate/:slug()",
    component: () => import("/codebuild/output/src1912607348/src/pages/donate/[slug].vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/codebuild/output/src1912607348/src/pages/donate/index.vue")
  },
  {
    name: "email-unsubscribe",
    path: "/email-unsubscribe",
    component: () => import("/codebuild/output/src1912607348/src/pages/email-unsubscribe.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/codebuild/output/src1912607348/src/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src1912607348/src/pages/events/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/codebuild/output/src1912607348/src/pages/forgot-password.vue")
  },
  {
    name: _91slug_93DqusU4MnMzMeta?.name,
    path: "/groups/:slug()",
    component: () => import("/codebuild/output/src1912607348/src/pages/groups/[slug].vue"),
    children: [
  {
    name: "groups-slug-files",
    path: "files",
    component: () => import("/codebuild/output/src1912607348/src/pages/groups/[slug]/files.vue")
  },
  {
    name: "groups-slug-gallery",
    path: "gallery",
    component: () => import("/codebuild/output/src1912607348/src/pages/groups/[slug]/gallery.vue")
  },
  {
    name: "groups-slug",
    path: "",
    component: () => import("/codebuild/output/src1912607348/src/pages/groups/[slug]/index.vue")
  },
  {
    name: "groups-slug-shop",
    path: "shop",
    component: () => import("/codebuild/output/src1912607348/src/pages/groups/[slug]/shop.vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/codebuild/output/src1912607348/src/pages/groups/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1912607348/src/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/codebuild/output/src1912607348/src/pages/join.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/codebuild/output/src1912607348/src/pages/login.vue")
  },
  {
    name: "membership-confirmation",
    path: "/membership/confirmation",
    component: () => import("/codebuild/output/src1912607348/src/pages/membership/confirmation.vue")
  },
  {
    name: "membership",
    path: "/membership",
    meta: indexYHxRnrVHCdMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/membership/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/codebuild/output/src1912607348/src/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/codebuild/output/src1912607348/src/pages/news/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/codebuild/output/src1912607348/src/pages/reset-password.vue")
  },
  {
    name: "setup-2fa",
    path: "/setup-2fa",
    meta: setup_452fa1OqCBp8DTPMeta || {},
    component: () => import("/codebuild/output/src1912607348/src/pages/setup-2fa.vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/codebuild/output/src1912607348/src/pages/shop.vue")
  }
]